<main class="u_overflow-hidden h-100">
  @if (!isScreenSharingSupported()) {
    <div class="top-menu">
      <div>
        <img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg" width="100">
        <a href="#">Need help?</a>
      </div>
    </div>
    <div class="join-panel aligner p-4">
      <div class="container p-5 flex-col aligner">
        <div class="flex-grow flex-col flex-wrap aligner mr-4 ml-4">
          <img class="w-25 mb-3" src="/images/screen-sharing/download-app.png" alt="Downlad App">
          <h4 class="mt-4 mb-4">Download App</h4>
          <p>To share your screen from your phone, you need to download the Screen Share by Rise Vision app.</p>
          <img class="w-100 p-1" src="/images/screen-sharing/google-play.png" alt="Googe Play">
          <img class="w-100 p-1" src="/images/screen-sharing/app-store.png" alt="App Store">
        </div>
      </div>
    </div>
  }
  @else if (!joined) {
    <div class="flex-row">
      <div class="join-panel flex-col p-4">
        <div class="flex-row space-between py-2 pl-3">
          <img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg" width="100">
          <a class="madero-link p-3" target="_blank" href="https://help.risevision.com/hc/en-us/articles/29009023259156-How-do-I-Set-Up-Screen-Sharing-on-a-display-in-Rise-Vision-Coming-Soon">Need help?</a>
        </div>
        <div class="aligner flex-grow">
          <div class="join-content">
            @if (screenSharingService.channelError) {
              <div class="madero-style alert alert-danger">
                <div class="flex flex-row align-start">
                  <mat-icon fontIcon="fa-times-circle" class="icon-left"></mat-icon>
                  <p class="mb-0">
                    {{ screenSharingService.channelError}}
                  </p>
                </div>
              </div>
            }
            <h3 class="font-weight-bold">Share your screen</h3>
            <p>Enter your name and the session code on the display to join a room and share your screen.</p>
            <div class="form-group required pt-3">
              <label>Session Code</label>
              <div class="join-code-input">
                <code-input
                  #codeInput
                  [codeLength]="codeLength"
                  [isCharsCode]="true"
                  inputType="text"
                  inputMode="text"
                  [initialFocusField]="0"
                  autocapitalize="characters"
                  (codeChanged)="codeChanged($event)"
                  (codeCompleted)="codeCompleted($event)"
                />
              </div>
              <p [shown]="screenSharingService.joinCodeError" class="help-block validation-error text-danger">{{screenSharingService.joinCodeError}}</p>
              <p [shown]="codeBlank" class="help-block validation-error text-danger">Please enter the complete Session Code</p>
            </div>
            <div class="form-group required py-3">
              <label>Name</label>
              <input #nameInput class="form-control light-border" />
              <p [shown]="nameBlank" class="help-block validation-error text-danger">This field cannot be left blank</p>
            </div>
            <button mat-flat-button color="primary" class="btn-block mt-5" [disabled]="screenSharingService.connecting" (click)="join()">
              @if(screenSharingService.connecting) {
                <mat-spinner diameter="15" class="white"></mat-spinner>
              } @else {
                Start Sharing
              }
            </button>
            @if (userStateService.isModerator()) {
              <div class="align-center mt-5 py-3" *requireRole="'sm'">
                <a class="p-3 madero-link" uiSref="apps.screen-sharing.moderator-join" [uiOptions]="{ reload: true }">Or join as moderator</a>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="background-panel"></div>
    </div>
  }
  @else {
    <div class="container p-5 h-100vh flex-col aligner">
      <div class="flex-grow flex-col flex-wrap aligner mt-5 joined-content">
        @if (waiting) {
          <img src="/images/screen-sharing/screen-sharing-wait.png" width="40" height="40" class="mb-5" alt="Waiting">
        }
        @else if (paused) {
          <img src="/images/screen-sharing/screen-sharing-paused.png" width="40" height="40" class="mb-5" alt="Paused">
        }
        @else if (stopped) {
          <img src="/images/screen-sharing/screen-sharing-stopped.png" width="40" height="40" class="mb-5" alt="Stopped">
        }
        @else {
          <img src="/images/screen-sharing/screen-sharing-active.png" width="40" height="40" class="mb-5" alt="Sharing">
        }
        @if (screenSharingService.channelError) {
          <div class="alert alert-danger">{{ screenSharingService.channelError }}</div>
        }
        <div class="font-weight-bold py-5">
          @if (waiting) {
            Wait for the moderator to invite you to share
          }
          @else if (paused) {
            Paused
          }
          @else if (stopped) {
            Stopped
          }
          @else {
            Sharing your screen...
          }
        </div>
        <p class="pb-2">
          @if (waiting) {
            <div class="moderator-list">
              To share your screen, you must be invited by <span [innerHTML]="moderatorName"></span>
            </div>
          }
          @else if (paused) {
            Click "Resume" to resume screen sharing
          }
          @else if (stopped) {
            Click "Start Sharing" to share your screen
          }
          @else {
            Your screen is being shared to the display
          }
        </p>
        @if (!waiting) {
          @if (stopped) {
            <button mat-flat-button color="primary" class="btn-block" (click)="start()" [disabled]="screenSharingService.connecting">
              @if (screenSharingService.connecting) {
                <mat-spinner diameter="15" class="white"></mat-spinner>
              } @else {
                Start Sharing
              }
            </button>
          }
          @else {
            @if (paused) {
              <button mat-stroked-button color="primary" class="btn-block" (click)="resume()">
                <mat-icon svgIcon="play" aria-hidden="true"></mat-icon>
                Resume
              </button>
            }
            @else if (!rvshareAppDetectionService.isSharingViaApp()) {
              <button mat-stroked-button color="primary" class="btn-block" (click)="pause()">
                <mat-icon svgIcon="pause" aria-hidden="true"></mat-icon>
                Pause
              </button>
            }
            <button mat-stroked-button color="primary" class="btn-block" (click)="stop()">
              <mat-icon svgIcon="stop-circle" aria-hidden="true"></mat-icon>
              Stop
            </button>
          }
        }
      </div>
      @if (!rvshareAppDetectionService.isSharingViaApp()) {
        <div>
          <button mat-button class="px-4" (click)="end()">
            <mat-icon svgIcon="exit" aria-hidden="true"></mat-icon>
            Exit Session
          </button>
        </div>
      }
    </div>
  }
</main>
